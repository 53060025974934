import Navbar from '../Navbar';
import './App.css';
import Products from '../Products';
import Projects from '../Projects';
import Footer from '../Footer';
import Form from '../Contact';
import AboutUs from '../AboutUs';
import AboutUsInfo from '../AboutUsInfo';
import Values from '../Values';
import Landing from "../Landing/Landing";
import WhatAppFloating from "../WhatAppFloating";

function App() {
  return (
    <div>
      <Navbar />
      <Landing />
      <AboutUs />
      <AboutUsInfo />
      <Values />
      <Products />
      <Projects />
      <Form />
      <Footer />
      <WhatAppFloating />
    </div>
  );
}

export default App;
