import './Projects.css';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import { styled } from '@mui/material/styles';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ProjectsTable from './ProjectsTable';
import mercovial from '../../resources/projects/list/mercovial.png';
import epe from '../../resources/projects/list/epe.png';
import santafe from '../../resources/projects/list/santafe.png';
import haizeasica from '../../resources/projects/list/haizeasica.png';
import vialagro from '../../resources/projects/list/vialagro.png';


const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion elevation={0} square {...props} />
))(() => ({
  border: `1px solid #ee8a42`,
  borderLeft: '0px',
  borderRight: '0px',
  padding: '1em',
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

function SimpleAccordion() {
  return (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          sx={{padding: '0 3em'}}
        >
          <div className='project-title'>
            <span className='project-number'>/1</span>
            <span className='project-name'>EMPRESAS FERROVIARIAS</span>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className='project'>
            <img src={mercovial} alt='project logo' width='150px'/>
            <p className='project-description'>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
              sed diam nonummy nibh euismod tincidunt ut laoreet dolore
              magna aliquam erat volutpat. Ut wisi enim ad minim veniam,
              quis nostrud exerci tation ullamcorper suscipit lobor
            </p>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          sx={{padding: '0 3em'}}
        >
          <div className='project-title'>
            <span className='project-number'>/2</span>
            <span className='project-name'>EMPRESAS DE OBRAS CIVILES</span>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className='project'>
            <img src={vialagro} alt='project logo' width='150px'/>
            <p className='project-description'>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
              sed diam nonummy nibh euismod tincidunt ut laoreet dolore
              magna aliquam erat volutpat. Ut wisi enim ad minim veniam,
              quis nostrud exerci tation ullamcorper suscipit lobor
            </p>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          sx={{padding: '0 3em'}}
        >
          <div className='project-title'>
            <span className='project-number'>/3</span>
            <span className='project-name'>EMPRESAS METALÚRGICAS</span>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className='project'>
            <img src={haizeasica} alt='project logo' width='150px'/>
            <p className='project-description'>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
              sed diam nonummy nibh euismod tincidunt ut laoreet dolore
              magna aliquam erat volutpat. Ut wisi enim ad minim veniam,
              quis nostrud exerci tation ullamcorper suscipit lobor
            </p>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          sx={{padding: '0 3em'}}
        >
          <div className='project-title'>
            <span className='project-number'>/4</span>
            <span className='project-name'>EMPRESAS EL MINISTERIO DE SEGURIDAD DE LA PROVINCIA DE SANTA FE</span>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className='project'>
            <img src={santafe} alt='project logo' width='150px'/>
            <p className='project-description'>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
              sed diam nonummy nibh euismod tincidunt ut laoreet dolore
              magna aliquam erat volutpat. Ut wisi enim ad minim veniam,
              quis nostrud exerci tation ullamcorper suscipit lobor
            </p>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          sx={{padding: '0 3em'}}
        >
          <div className='project-title'>
            <span className='project-number'>/5</span>
            <span className='project-name'>EMPRESAS PROVINCIAL DE ENERGÍA DE SANTA FE Y AGUAS SANTAFESINAS</span>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className='project'>
            <img src={epe} alt='project logo' width='150px'/>
            <p className='project-description'>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
              sed diam nonummy nibh euismod tincidunt ut laoreet dolore
              magna aliquam erat volutpat. Ut wisi enim ad minim veniam,
              quis nostrud exerci tation ullamcorper suscipit lobor
            </p>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}


const Projects = () => {
  return (
    <section id="portfolio" className='projects'>
      <div className='projects-heading'>
        <hr className='horizontal-line'/>
        <span className='projects-title'>PROYECTOS HOPE</span>
      </div>
      <SimpleAccordion />
      <ProjectsTable />
    </section>
  )
};

export default Projects;
