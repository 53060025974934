import Cell from './Cell';
import './ProjectsTable.css';
import cell1 from '../../../resources/projects/cell1.png';
import cell2 from '../../../resources/projects/cell2.png';
import cell3 from '../../../resources/projects/cell3.png';
import cell4 from '../../../resources/projects/cell4.png';

const ProjectsTable = () => {

  return (
    <div className="projects-table">
      <div className='projects-row'>
        <Cell title='PARA LA POLICIA DE SANTA FE' description='oficina de 30 metros cuadrados.' img={cell1}/>
        <Cell title='PARA LA U.E. CORREDOR VIAL N6' description='oficina de 30 metros cuadrados.' img={cell2}/>
      </div>
      <div className='projects-row'>
        <Cell title='PARA LA POLICIA DE SANTA FE' description='oficina de 30 metros cuadrados.' img={cell3}/>
        <Cell title='PARA LA U.E. CORREDOR VIAL N6' description='oficina de 30 metros cuadrados.' img={cell4}/>
      </div>
    </div>
  )
};

export default ProjectsTable;
