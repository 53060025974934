import './AboutUsInfo.css';
import info1 from '../../resources/aboutUs/infoIcon1.png';
import info2 from '../../resources/aboutUs/infoIcon2.png';

const InfoRow = ({ isLeft = false, isIcon = false, left, right }: { isLeft?: boolean, isIcon?: boolean, left: any, right: any }) => {
  if (isLeft) {
    if (isIcon) {
      return (
        <div className='info-row'>
          <img src={left} alt="icon"/>
          <div className='info-row-small'>{right}</div>
        </div>
      )
    }

    return (
      <div className='info-row'>
        <div className='info-row-big'>{left}</div>
        <div className='info-row-small'>{right}</div>
      </div>
    )
  }

  if (isIcon) {
    return (
      <div className='info-row'>
        <div className='info-row-small'>{left}</div>
        <img src={right} alt="icon"/>
      </div>
    )
  }

  return (
    <div className='info-row'>
      <div className='info-row-small'>{left}</div>
      <div className='info-row-big'>{right}</div>
    </div>
  )
}
const AboutUsInfo = () => {

  return (
    <section className='about-us-info'>
      <InfoRow isLeft left="+10" right="proyectos por año" />
      <InfoRow left="clientes" right="+20"/>
      <InfoRow isLeft isIcon left={info1} right="modulos de oficina, baños, quinchos y más"/>
      <InfoRow left="envíos a todo el país" isIcon right={info2}/>
    </section>
  )
};

export default AboutUsInfo;
