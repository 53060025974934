import './Landing.css';

const Landing = () => {

  return (
  <section className="landing-container">
    <div className='landing-content'>
      <span className='slogan'>
        LOS CONTENEDORES IDEALES
        <br />
        PARA TUS PROYECTOS
      </span>
      <a href="#contact" className='home-button'>contactános</a>
    </div>

    <img src='/images/landing-quincho.png' alt="Home Background" className="landing-img"/>
  </section>
  )
};

export default Landing;
