import './Cell.css';

const Cell = ({title,description, img}: {title: string, description: string, img: any}) => {
  return (
    <div className='cell'>
      <span className='cell-title'>{title}</span>
      <span className='cell-description'>{description}</span>
      <img src={img} alt={title} width={'80%'} />
    </div>
  );
};

export default Cell;
