import React from "react";
import './Navbar.css';
import { useState } from "react";
import { ReactComponent as HamburgerIcon } from '../../resources/icons/hamburger.svg'
import classNames from 'classnames';

const Navbar = () => {
  const [isNavExpanded, setIsNavExpanded] = useState(false);

  return (
    <nav className="navigation">
      <a href="/">
        <img src='/images/logo.png' alt="Logo" className="logo-img"/>
      </a>
      <button
        className="hamburger"
        onClick={() => {
          setIsNavExpanded(!isNavExpanded);
        }}
      >
        <HamburgerIcon />
      </button>
      <div
        className={classNames('navigation-menu', isNavExpanded ? 'expanded' : null)}
      >
        <ul>
          <li>
            <a href="#home">Inicio</a>
          </li>
          <li>
            <a href="#about">Valores</a>
          </li>
          <li>
            <a href="#products">Productos</a>
          </li>
          <li>
            <a href="#portfolio">Trabajos</a>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
