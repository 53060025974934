import './Footer.css';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined';

const Footer = () => {
  return (
    <div className="footer">
      <div className='footer-left'>
        <span className='footer-title'>HOPE SA</span>
        <p>
          Somos una empresa de construcción,
          remodelación, venta y alquiler de
          contenedores personalizados.
          Brindamos soluciones innovadoras y
          sostenibles para tus proyectos.
        </p>
      </div>
      <div className='footer-right'>
        <span>hopecontenedores@gmail.com<MailOutlineIcon fontSize='small'/></span>
        <span>+54 3496-557841 <WhatsAppIcon fontSize='small'/></span>
        <a href="https://www.instagram.com/hopesacontenedores/" rel="noreferrer" target="_blank">@hopesacontenedores <InstagramIcon fontSize='small'/></a>
        <a href="https://maps.app.goo.gl/fvNit446nADMydc2A/" rel="noreferrer" target="_blank">Parque industrial de Esperanza, Santa Fe<PinDropOutlinedIcon /></a>
        <span></span>
      </div>
    </div>
  )
};

export default Footer;
