import './ProductButton.css';

const ProductButton = ({number, title, description}: {number: string, title: string, description?: string}) => {
  return (
    <div className="product-button">
      <span className='prod-button-number'>{number}</span>
      <span className='prod-button-title'>{title}</span>
      {description ?? <span className='prod-button-description'>{description}</span>}
    </div>
  );
}

export default ProductButton;
