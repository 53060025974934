import { useState } from "react";
import agro0 from '../../../resources/agro/agro0.png';
import agro1 from '../../../resources/agro/agro1.png';
import agro2 from '../../../resources/agro/agro2.png';
import './Agro.css';


const Agro = () => {

  const [imageIndex, setImageIndex ] = useState(0);
  const [isSummaryOpen, setIsSummaryOpen] = useState(false);

  const getImage = () => {
    switch(imageIndex) {
      case 0:
        return (<img onClick={() => handleImageClick(0)} src={agro0} alt="agro"/>)
      case 1:
        return (<img onClick={() => handleImageClick(1)} src={agro1} alt="agro"/>)
      case 2:
        return (<img onClick={() => handleImageClick(2)} src={agro2} alt="agro"/>)
    }
  };

  const handleImageClick = (index: number) => {
    if(!isSummaryOpen) {
      setIsSummaryOpen(true);
      setImageIndex(index);
    }
    else {
      setImageIndex(0);
      setIsSummaryOpen(false);
    }
  }
  const getSummary = () => {
    if(!isSummaryOpen) {
      return ;
    }
    switch(imageIndex) {
      case 0:
        return (
          <div className="agroSummaryText">
            <hr className='horizontal-line'/>
            <span className='industrial-title'>LÍNEA AGRO</span>
            <p>
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
              sed diam nonummy nibh euismod tincidunt ut laoreet dolore
              magna aliquam erat volutpat. Ut wisi enim ad minim veniam,
              quis nostrud exerci tation ullamcorper suscipit lobor
            </p>
            <div className="summarySubText">
              <span className='prod-button-number'>/1</span>
              <span className='prod-button-title'>Casillas rodantes</span>
            </div>
          </div>
          )
      case 1:
                return (
          <div className="agroSummaryText">
            <hr className='horizontal-line'/>
            <span className='industrial-title'>LÍNEA AGRO</span>
            <p>
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
              sed diam nonummy nibh euismod tincidunt ut laoreet dolore
              magna aliquam erat volutpat. Ut wisi enim ad minim veniam,
              quis nostrud exerci tation ullamcorper suscipit lobor
            </p>
            <div className="summarySubText">
              <span className='prod-button-number'>/2</span>
              <span className='prod-button-title'>Casillas rodantes</span>
            </div>
          </div>
          )
      case 2:
                return (
          <div className="agroSummaryText">
            <hr className='horizontal-line'/>
            <span className='industrial-title'>LÍNEA AGRO</span>
            <p>
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
              sed diam nonummy nibh euismod tincidunt ut laoreet dolore
              magna aliquam erat volutpat. Ut wisi enim ad minim veniam,
              quis nostrud exerci tation ullamcorper suscipit lobor
            </p>
            <div className="summarySubText">
              <span className='prod-button-number'>/3</span>
              <span className='prod-button-title'>Casillas rodantes</span>
            </div>
          </div>
          )
    }
  };

  return (
    <div className="agro">
      <div className="agroImage" onClick={() => setIsSummaryOpen(!isSummaryOpen)}>
        {getImage()}
      </div>
      {isSummaryOpen ?
       <div className="agroSummary">
        {getSummary()}
      </div> : null }

      {isSummaryOpen ? null :
        <div className="agroImage">
          <img onClick={() => handleImageClick(1)} src={agro1} alt="agro"/>
          <img onClick={() => handleImageClick(2)} src={agro2} alt="agro"/>
        </div> }

    </div>
  )
};

export default Agro;
