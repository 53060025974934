import './Values.css';

const Value = ({number, title, description}: {number: string, title: string, description: string}) => {
  return (
    <div className='value'>
      <span className='value-number'>{`/${number}`}</span>
      <span className='value-title'>{title}</span>
      <span className='value-description'>{description}</span>
    </div>
  )

};

const Values = () => {
  return (
    <div className='values'>
      <div className='projects-heading'>
        <hr className='horizontal-line'/>
        <span className='projects-title'>NUESTROS VALORES</span>
      </div>
      <div className='values-items'>
        <div className='values-row'>
          <Value number='1'
          title='Expertos en construccion'
          description='Priorizamos la calidad, la innovación y la estética. Optimizamos la elaboración de
                        nuestros productos con la mejor tecnologíay maquinaria.'
          />
          <Value number='2'
          title='Precio, tiempo y calidad'
          description='La rapidez y eciencia para cumplir con los
          plazos de entrega en tiempo y forma nos diferencia, además de una relación precio-calidad muy competitiva.'
          />
        </div>
        <div className='values-row'>
          <Value number='3'
          title='Servicio personalizado'
          description='Ponemos a nuestros clientes en el centro y hacemos un seguimiento detallado tanto en la elaboración del proyecto
          como en el post. Gracias a nuestra amplia gama de servicios podemos adaptarnos a cualquier necesidad.'
          />
          <Value number='4'
          title='Actitud sostenible'
          description='Reutilizamos nuestros contenedores para brindar una mirada más ecológica. A su vez, incorporamos elementos sostenibles
          que respeten al medioambiente.'
          />
        </div>
      </div>
    </div>
  )
};

export default Values;
