import './AboutUs.css';
import about from '../../resources/aboutUs/aboutUs.png';

const AboutUs = () => {

  return (
    <section id='about' className="about-us">
      <div className='about-us-col'>
        <img src={about} alt="about us" className='about-us-col'/>
      </div>
      <div className='projects-heading'>
        <hr className='horizontal-line'/>
        <span className='projects-title'>¿QUIÉNES SOMOS?</span>
        <p className='about-us-description'>
          Somos una empresa de construcción, remodelación,
          venta y alquiler de contenedores personalizados.
          Brindamos soluciones innovadoras con conciencia
          sostenible en el mundo de la construcción.
        </p>
      </div>
    </section>
  )
};

export default AboutUs;
