import './WhatAppFloating.css';
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const WhatAppFloating = () => {
  return (
      <a href="https://wa.me/543496557841" target="_blank" rel="noopener noreferrer" className="whatsapp-float">
        <WhatsAppIcon fontSize="large" />
      </a>
  )
};

export default WhatAppFloating;
