import Agro from './Agro';
import Industrial from './Industrial';
import Sanitary from './Sanitary';
import './Products.css';


const Products = () => {

  return (
    <section id="products" className="products">
      <Industrial />
      <Sanitary />
      <Agro />
    </section>
  )
};

export default Products;
