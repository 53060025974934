import './Industrial.css';
import industrial from '../../../resources/industrial/industrial.png';
import ProductButton from '../ProductButton';

const Industrial = () => {

  return (
    <div className="industrial">
      <div>
        <img src={industrial} alt='agro1' />
      </div>
      <div className='industrial-info'>
        <hr className='horizontal-line'/>
        <span className='industrial-title'>LÍNEA INDUSTRIAL</span>
        <p>
          Ideal tanto para ocinas como para obras.
          Construimos con materiales que se adaptan a tu
          presupuesto y necesidad, con posibilidades que
          van desde durlock hasta pisos de pvc o aberturas.
          Consultanos por alternativas económicas, estándar
          o premium.
        </p>
        <div className='industrial-row'>
          <ProductButton number='/1' title='Oficinas de 12 metros' description='(baño opcional).'/>
          <ProductButton number='/2' title='Oficinas de 6 metros' description='(baño opcional).'/>
        </div>
        <div>
          <ProductButton number='/3' title='Pañoles de 6 y 12 metros' description='(baño opcional).'/>
        </div>
      </div>
    </div>
  )
};

export default Industrial;
