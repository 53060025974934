import './Sanitary.css';
import sanitary from '../../../resources/sanitary/sanitary1.png';
import ProductButton from '../ProductButton';

const Sanitary = () => {

  return (
    <div className="sanitary">
      <div className='sanitary-info'>
        <hr className='horizontal-line'/>
        <span className='sanitary-title'>LÍNEA SANITARIOS</span>
        <p>
          Diseñamos tu sanitario ad hoc para cualquier tipo
          de industria. Con posibilidad de división entre
          hombre y mujer, bidet, duchas y más. Como todos
          nuestros productos, es personalizado y se congura
          en base a tu necesidad.
          Consultanos por alternativas económicas, estándar
          o premium.
        </p>
        <div className='sanitary-row'>
          <ProductButton number='/1' title='Baños de 6 metros' description='(ducha opcional).'/>
          <ProductButton number='/2' title='Baños de 3 metros'/>
        </div>
      </div>
      <div>
        <img src={sanitary} alt='sanitary' />
      </div>
    </div>
  )
};

export default Sanitary;
